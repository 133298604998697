import * as React from 'react'
import {container, desc, imageContainer, titleText} from '../styles/post-card.module.scss'
import {Link} from 'gatsby'
import slugify from '../utils/slugify'
import Img from 'gatsby-image'

const PostCard = ({title, author, description, date, link, image}) => {
    return (


        <div className={container}>
            <Link to={encodeURI(`/posts/${slugify(link)}`)}>
                <Img fluid={image} className={imageContainer} alt={title}/>
            </Link>
            <div>
                <div>
                    <Link to={encodeURI(`/posts/${slugify(link)}`)}>
                        <h5 className={titleText}>{title}</h5>
                    </Link>
                    <text className={desc}>{description}</text>
                </div>
            </div>
            <div>

            </div>
        </div>


    )
};


export default PostCard

import * as React from "react";
import Helmet from 'react-helmet';
import Layout from "../components/layout";
import {graphql} from 'gatsby';
import {container, postsContainer, topicTitle} from '../styles/topic.module.scss'
import PostCard from "../components/post-card";


const IndexPage = ({data}) => {
    return (
        <Layout pageTitle="Home">
            <Helmet>
                <meta charSet="utf-8"/>
                <meta name="description" content={`بلاگ شخصی امیر افسری درباره ${data.topics.title}`}/>
                <title>امیر‌افسری | {data.topics.title}</title>
                <link rel="canonical" href="https://amirafsari.com/"/>
            </Helmet>
            <div className={container}>
                <h1 className={topicTitle}>{data.topics.title}</h1>
                <div className={postsContainer}>
                    {data.posts.nodes.map(({frontmatter}) =>
                        <PostCard title={frontmatter.title}
                                  author={frontmatter.author}
                                  date={frontmatter.date}
                                  description={frontmatter.desc}
                                  link={frontmatter.slug} key={frontmatter.id}
                                  image={frontmatter.featuredImage.childImageSharp.fluid}/>)}
                </div>
            </div>
        </Layout>
    )
};


export const query = graphql`
  query TopicQuery($id: String, $topic: Int) {
    topics: topicsJson( id: { eq: $id }) {
        title
        key
    }
    posts: allMarkdownRemark(filter: {frontmatter: {topic: {eq: $topic}}}) {
        nodes {
          id
          frontmatter {
            slug
            title
            date
            author
            topic
            desc
            featuredImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
  }
`;


export default IndexPage
